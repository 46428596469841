import request from '@/tools/request';

import { ListRes, GetListData, Del, DelRes, EditLexicon, EditLexiconRes } from '../types/lexicon';

// 获取表格数据
export const getListData: GetListData = (params, loading) => {
  return request({
    params,
    loading,
    url: '/criteo-words-list.html',
  }) as Promise<ListRes>;
};

// 删除
export const del: Del = (params, loading) => {
  return request({
    url: '/criteo-words-del.html',
    params,
    loading,
  }) as Promise<DelRes>;
};

// 新增
export const createLexicon: EditLexicon = (data, loading) => {
  return request({
    url: '/criteo-words-add.html',
    data,
    loading,
    method: 'POST',
  }) as Promise<EditLexiconRes>;
};

// 新增
export const editLexicon: EditLexicon = (data, loading) => {
  return request({
    url: '/criteo-words-edit.html',
    data,
    loading,
    method: 'POST',
  }) as Promise<EditLexiconRes>;
};
