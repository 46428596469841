import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "feed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTab = _resolveComponent("PageTab")
  const _component_LexiconAdmin = _resolveComponent("LexiconAdmin")
  const _component_LexiconDefault = _resolveComponent("LexiconDefault")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("header", null, [
      _createVNode(_component_PageTab, {
        pageTitle: _ctx.pageTitle,
        onUpdate: _ctx.changeTab
      }, null, 8, ["pageTitle", "onUpdate"])
    ]),
    _createVNode("main", null, [
      (_ctx.pageTitle[0].isActive)
        ? (_openBlock(), _createBlock(_component_LexiconAdmin, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.pageTitle[1].isActive)
        ? (_openBlock(), _createBlock(_component_LexiconDefault, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}