
import { defineComponent, onMounted, ref } from 'vue';
import MTable from '@/components/table/Table.vue';
import { getRoute } from '@/tools/config';
import { createLexicon, del, editLexicon, getListData } from '../api/lexicon';
import { ElButton, ElInput, ElMessage, ElMessageBox, ElTableColumn } from 'element-plus';
import { TypedMessageParams } from 'element-plus/lib/el-message/src/types';
import { EditLexiconBody, TableList } from '../types/lexicon';
import LexiconDialog from './LexiconDialog.vue';

export default defineComponent({
  name: 'LexionAdmin',
  setup() {
    const tableList = ref<TableList[]>([]);
    const loading = ref(false);

    const pagination = ref({
      total: 0,
      pageSize: 10,
      currentPages: 1,
    });
    const keyword = ref('');
    // 初始化
    const initTable = async (page?: number) => {
      const res = await getListData(
        {
          page: page || 1,
          page_size: pagination.value.pageSize,
          keyword: keyword.value,
          default: 0,
        },
        loading,
      );

      if (!res.status) return;

      const { data } = res;

      tableList.value = res.data?.list || [];

      pagination.value.total = data?.pages?.total || 0;
      pagination.value.pageSize = data?.pages?.page_size || 10;
    };

    // 换页
    const pageChange = cur => {
      pagination.value.currentPages = cur;
      initTable(cur);
    };

    // 搜索
    const search = () => {
      initTable();
    };

    // 删除
    const triggerDel = async (element: TableList) => {
      ElMessageBox.confirm('此操作将永久删除该配置，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          element.delLoading = true;
          const { status } = await del({ id: element.id }, ref(false));
          element.delLoading = false;
          if (status) {
            initTable(pagination.value.currentPages);
            ElMessage.success({
              message: '删除成功',
            } as TypedMessageParams<'success'>);
          }
        })
        .catch(() => {});
    };

    const isShowDialog = ref(false);
    const formData = ref<EditLexiconBody>({ content: '', replace: '' });

    // 添加
    const addLexicon = () => {
      isShowDialog.value = true;
      formData.value = {
        content: '',
        replace: '',
      };
    };

    // 编辑
    const changeLexicon = (item: TableList) => {
      isShowDialog.value = true;
      formData.value = {
        id: item.id,
        content: item.content,
        replace: item.replace,
      };
    };

    const editLoading = ref(false);
    const tableKey = ref(0);
    const saveEdit = async val => {
      let data = val as EditLexiconBody; // 解决编辑器报错问题

      let isEdit = !!data.id; // 如果有 id，则是编辑状态
      const { status } = isEdit
        ? await editLexicon(data, editLoading)
        : await createLexicon(data, editLoading);

      if (!status) return;

      // 如果是编辑，则保留当前页，否则跳转到第一页
      if (isEdit) {
        initTable(pagination.value.currentPages);
      } else {
        initTable();
        tableKey.value++; // 重新加载组件 m-table
      }

      closeDialog();

      ElMessage({
        type: 'success',
        message: '保存成功',
      });
    };

    const closeDialog = () => {
      isShowDialog.value = false;
    };

    onMounted(() => {
      initTable();
    });

    return {
      tableList,
      loading,
      getRoute,
      pagination,
      triggerDel,
      addLexicon,
      isShowDialog,
      formData,
      saveEdit,
      closeDialog,
      pageChange,
      tableKey,
      changeLexicon,
      editLoading,
      keyword,
      search,
    };
  },
  components: { MTable, LexiconDialog, ElInput, ElTableColumn, ElButton },
});
