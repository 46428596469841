
import { ElButton, ElDialog, ElForm, ElFormItem, ElInput } from 'element-plus';
import { defineComponent, PropType, ref, watchEffect } from 'vue';
import { EditLexiconBody } from '../types/lexicon';

export default defineComponent({
  name: 'LexiconDialog',
  components: { ElFormItem, ElForm, ElButton, ElInput, ElDialog },
  props: {
    controlShowDialog: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formData: {
      type: Object as PropType<EditLexiconBody>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ['cancel', 'confirm', 'close'],
  setup(props, { emit }) {
    const isShowDialog = ref(false);
    const data = ref<EditLexiconBody>({
      content: '',
      replace: '',
    });
    watchEffect(() => {
      isShowDialog.value = props.controlShowDialog;
    });
    watchEffect(() => {
      data.value = props.formData;
    });

    const cancel = () => {
      emit('cancel');
    };
    const confirm = () => {
      emit('confirm', data.value);
    };
    const close = () => {
      emit('close');
    };
    return {
      isShowDialog,
      cancel,
      confirm,
      data,
      close,
    };
  },
});
