
import { defineComponent, onMounted, ref } from 'vue';
import MTable from '@/components/table/Table.vue';
import { getRoute } from '@/tools/config';
import { getListData } from '../api/lexicon';
import { ElButton, ElInput, ElTableColumn } from 'element-plus';
import { TableList } from '../types/lexicon';

export default defineComponent({
  name: 'LexionDefault',
  setup() {
    const tableList = ref<TableList[]>([]);
    const loading = ref(false);

    const pagination = ref({
      total: 0,
      pageSize: 10,
      currentPages: 1,
    });
    const keyword = ref('');
    // 初始化
    const initTable = async (page?: number) => {
      const res = await getListData(
        {
          page: page || 1,
          page_size: pagination.value.pageSize,
          keyword: keyword.value,
          default: 1, // 默认词库
        },
        loading,
      );

      if (!res.status) return;

      const { data } = res;

      tableList.value = res.data?.list || [];

      pagination.value.total = data?.pages?.total || 0;
      pagination.value.pageSize = data?.pages?.page_size || 10;
    };

    // 换页
    const pageChange = cur => {
      pagination.value.currentPages = cur;
      initTable(cur);
    };

    // 搜索
    const search = () => {
      initTable();
    };

    onMounted(() => {
      initTable();
    });

    return {
      tableList,
      loading,
      getRoute,
      pagination,
      pageChange,
      keyword,
      search,
    };
  },
  components: { MTable, ElTableColumn, ElButton, ElInput },
});
