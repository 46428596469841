
import { defineComponent, ref } from 'vue';
import PageTab from '@/components/pageTab/PageTab.vue';
import LexiconDefault from '../components/LexiconDefault.vue';
import LexiconAdmin from '../components/LexiconAdmin.vue';

export default defineComponent({
  name: 'Feed',
  setup() {
    const pageTitle = ref([
      {
        text: '词库管理',
        isActive: true,
      },
      {
        text: '默认词库',
      },
    ]);
    // 换页
    const changeTab = (index: number) => {
      pageTitle.value.forEach(item => {
        item.isActive = false;
      });

      pageTitle.value[index].isActive = true;
    };
    return {
      changeTab,
      pageTitle,
    };
  },
  components: { PageTab, LexiconDefault, LexiconAdmin },
});
